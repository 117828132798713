<template>
  <el-card class="page-card">
    <slot name="header" class="clearfix">
      <el-button type="primary" @click="add" v-if="max === 0 || count < max"
        >{{ $t("common.add") }} {{ $t(name) }}</el-button
      >
      <el-descriptions v-else :title="$t(name)"></el-descriptions>
    </slot>
    <sui-list
      ref="list"
      :column="tableColumn"
      :fun="tableFun"
      :req="tableFunReq"
      @edit="edit"
      @del="del"
      @hidden="hidden"
      @backflow="backflow"
    />
    <el-drawer
      :title="`${$t(formData.id ? 'common.edit' : 'common.add')} ${$t(name)}`"
      :visible="editing"
      size="500px"
      close-on-press-escape
      @close="close"
    >
      <sui-form
        ref="form"
        :list="formList"
        :defaultdata="formData"
        @submit="submit"
      />
    </el-drawer>
  </el-card>
</template>
<script>
import SuiForm from "@/components/s-ui/form";
import SuiList from "@/components/s-ui/list";
import { formatDataTime } from "@/utils";
import { AddBrand, GetBrandList, EditBrand, DelBrand } from "@/utils/api";

// form的默认参数
const defaultFormData = {
  brandName: "",
  img: "",
};
export default {
  props: {
    name: {
      type: String,
      value: "",
    },
    max: {
      type: Number,
      value: 0,
    },
  },
  components: { SuiForm, SuiList },
  data() {
    return {
      editing: false,
      // form的内容
      formList: [
        // id
        {
          type: "input",
          name: "ID",
          hidden: true,
        },
        // 品牌名称
        {
          type: "input",
          name: "brandName",
          label: "brandList.brandName",
          rules: [
            {
              required: true,
              message: "brandList.brandReq",
              trigger: "blur",
            },
          ],
        },
        // 上传图片
        {
          type: "uploadimage",
          mode: "material",
          name: "img",
          label: "brandList.img",
        },
      ],
      // form的默认参数
      formData: {},
      // list的内容
      tableColumn: [
        // 品牌名称
        {
          name: "brandName",
          label: "brandList.brandName",
        },
        // 图片
        {
          type: "image",
          name: "img",
          label: "brandList.img",
        },
        // 创建时间
        {
          name: "createAt",
          label: "content.exchaterate.createAt",
          formatFn: formatDataTime,
          width:110
        },
        // 更新时间
        {
          name: "updateAt",
          label: "content.exchaterate.updateAt",
          formatFn: formatDataTime,
          width:110
        },
      ],
      // list的请求接口
      tableFun: GetBrandList,
      // list的默认参数
      tableFunReq: {
        orderKey: "",
        pageSize: this.max || 10,
      },
      count: 0,
    };
  },
  methods: {
    // 添加
    add() {
      this.editing = true;
      const formData = {
        type: this.type,
        ...defaultFormData,
      };
      this.formData = formData;
      this.$nextTick(() => {
        this.formData = formData;
      });
    },
    // 编辑
    edit(rowData) {
      console.log("edit-rowData:", rowData);
      // this.formData = rowData;
      this.editing = true;
      this.$nextTick(() => {
        // this.$refs["form"].setForm(rowData);
        this.formData = rowData;
      });
    },
    // 删除
    del(rowData) {
      console.log("del-rowData:", rowData);
      DelBrand({
        id: rowData.id,
      })
        .then((res) => {
          console.log("DelBanner-res:", res);
          this.$message({
            type: "success",
            message: this.$t("common.deleteSuccess"),
          });
          this.$refs["list"].getList();
        })
        .catch((err) => {
          console.log("DelBanner-err:", err);
        });
    },
    // hidden的切换
    hidden(e, rowData, index) {
      console.log("hidden:", e, rowData, index);
      this.iEditBanner(rowData.id, { isHidden: e }).then(() => {
        this.$message({
          type: "success",
          message: this.$t("common.optSuccess"),
        });
        this.$refs["list"].getList();
      });
    },
    // 分页
    backflow(count) {
      this.count = count;
    },
    // hidden的编辑
    iEditBanner(id, params) {
      return EditBrand({
        id,
        ...params,
      });
    },
    // 点击提交
    submit(data) {
      console.log("submit:", data);
      const fn = data.id ? EditBrand : AddBrand;
      fn(data)
        .then((res) => {
          console.log("AddBanner-res:", res);
          this.$message({
            type: "success",
            message: this.$t("common.createSuccess"),
          });
          this.editing = false;
          this.$refs["list"].getList();
        })
        .catch((err) => {
          console.log("AddBanner-err:", err);
        });
    },
    // 关闭侧边栏
    close() {
      this.editing = false;
      this.formData = {};
      this.$refs["form"].resetFields();
    },
  },
};
</script>

<style lang="scss">
.hd {
  &-row {
    color: #333;
    font-weight: normal;
  }
}
.bd {
  &-row {
    text-align: center;
  }
}
page-card {
  .el-icon-upload {
    padding-top: 30px;
  }
}
</style>