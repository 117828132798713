<template>
  <div>
    <brand-list
      name="common.brand"
      :max="0"
      class="brand-list"
    />
  </div>
</template>
<script>
import BrandList from "@/components/BrandList";

export default {
  components: {
    BrandList,
  },
  data() {
    return {}
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.brand-list {
  margin-bottom: 15px;
}
</style>